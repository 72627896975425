<template>
	<div>
		<div class="form-group">

			<p v-if="type_of_invoice == 'debours'">
				{{ $t('tiers.facturation_en_debours') }}
			</p>
			<p v-if="type_of_invoice == 'avf'">
				{{ $t('tiers.facturation_en_avf') }}
			</p>

			<b-form-radio 
				id="associer_cheval"
				v-model="association" 
				value="cheval"
				>
				{{ $t("tiers.associer_cheval") }}
			</b-form-radio>
			<b-form-radio 
				id="associer_tiers"
				v-model="association" 
				value="tiers"
				v-if="type_of_invoice == 'avf'"
				>
				{{ $t("tiers.associer_tiers") }}
			</b-form-radio>

			<div class="row">
				<div class="col-12" v-if="association == 'cheval'">
					<label for="horses">{{ $t('tiers.associer_cheval') }}</label>
					<e-select
						v-model="horse"
						id="horses"
						track-by="name"
						label="name"
						:placeholder="labelTitleHorses"
						:selectedLabel="selectedLabel"
						:options="horses"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div class="col-12" v-else>
					<label for="tiers">{{ $t('tiers.associer_tiers') }}</label>
					<e-select
						v-model="tier"
						id="tiers"
						track-by="tiers_id"
						label="tiers_formatted"
						:placeholder="labelTitleTiers"
						:selectedLabel="selectedLabel"
						:options="tiers"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
					>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label for="tiers_entity">{{ $t('tiers.billing_entity') }} *</label>
			<e-select
				v-model="entity_model"
				id="tiers_entity"
				track-by="tiers_id"
				label="tiers_rs"
				:placeholder="getTrad('tiers.rechercher_billing_entity')"
				:selectedLabel="getTrad('tiers.rechercher')"
				:options="entity"
				:searchable="true"
				:allow-empty="false"
				:loading="isLoadingEntity"
				:show-labels="false"
			>
			</e-select>
		</div>

		<template v-if="entity_model != null">
			<template v-if="toggle_form == false">
				<label for="articles">{{ $t('tiers.choisir_article') }} *</label>
				<e-select
					v-model="article"
					id="articles"
					track-by="articles_id"
					label="articles_label"
					:placeholder="labelTitleArticles"
					:selectedLabel="selectedLabel"
					:options="articles"
					:searchable="true"
					:show-labels="false"
					:allow-empty="true"
					:class="{ 'is-invalid': errors && errors.indexOf('article_name_exist') > -1 }"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
				<small class="form-text text-muted"><a href="" @click.prevent="toggle_create_article(true)">{{ $t('tiers.creer_article') }}</a></small>
			</template>

			<template v-if="toggle_form">
				<div class="row">
					<div class="col-6">
						<div class="form-group">
							<label for="article-name" class="col-form-label">{{ $t("tiers.nom_article") }} *</label>
							<input type="text" id="article-name" class="form-control" v-model="article_name" @blur="correctCode" :class="{ 'is-invalid': errors && errors.indexOf('article_name') > -1 }">
							<small class="form-text text-muted"><a href="" @click.prevent="toggle_create_article(false)">{{ $t('tiers.utiliser_article_existant') }}</a></small>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label for="articles_code" class="col-form-label">{{ $t("compta.form.code_article") }} *</label>
							<input type="text" id="articles_code" class="form-control" v-model="articles_code" :class="{ 'is-invalid': errors && errors.indexOf('articles_code') > -1 }">
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<div class="form-group">
						    <label for="articles_articlestype" class="col-form-label">{{ $t("compta.form.articles_type") }} *</label>
						     <e-select
						        v-model="articles_articlestype"
						        id="articles_articlestype"
						        track-by="articlestype_id"
						        label="articlestype_label"
						        :placeholder="labelTitleArticlesType"
						        :deselectLabel="deselectLabel"
						        :selectedLabel="selectedLabel"
						        :selectLabel="enter_to_select"
						        :options="articles_type"
						        :searchable="true"
						        :show-labels="false"
						        :allow-empty="false"
						        :class="{ 'is-invalid': errors && errors.indexOf('Erreur articles type') > -1 }"
						    />
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label for="">{{ $t('compta.form.compte') }}</label>
							<e-select
								v-model="accounting_account"
								id="accounting_account"
								track-by="accountingaccount_id"
								label="accountingaccount_label"
								:options="tab_accounting_account"
							>
							</e-select>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
						    <label for="articles_vataccount" class="col-form-label">{{ $t("compta.form.vat_compte_comptable") }} *</label>
						    <e-select
						        v-model="articles_vataccount"
						        id="articles_vataccount"
						        track-by="accountingaccount_id"
						        label="accountingaccount_label"
						        :placeholder="labelTitleCompteComptable"
						        :deselectLabel="deselectLabel"
						        :selectedLabel="selectedLabel"
						        :selectLabel="enter_to_select"
						        :options="tab_accounting_account_vat"
						        :searchable="true"
						        :show-labels="false"
						        :allow-empty="false"
						        :class="{ 'is-invalid': errors && errors.indexOf('Erreur vat compte comptable') > -1 }"
						    >
						        <template slot="singleLabel" slot-scope="{ option }">{{ option.accountingaccount_label }}</template>
						        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						    </e-select>
						    
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
						    <label for="articles_accountingaccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
						    <e-select
						        v-model="articles_accountingaccountfdvxp"
						        id="articles_accountingaccountfdvxp"
						        track-by="accountingaccount_id"
						        label="accountingaccount_label"
						        :placeholder="labelTitleCompteComptable"
						        :deselectLabel="deselectLabel"
						        :selectedLabel="selectedLabel"
						        :selectLabel="enter_to_select"
						        :options="tab_accounting_account"
						        :searchable="true"
						        :show-labels="false"
						        :allow-empty="false"
						        :class="{ 'is-invalid': errors && errors.indexOf('Erreur compte comptable fdvxp') > -1 }"
						    >
						        <template slot="singleLabel" slot-scope="{ option }">{{ option.accountingaccount_label }}</template>
						        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						    </e-select>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
						    <label for="articles_vataccountfdvxp" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
						    <e-select
						        v-model="articles_vataccountfdvxp"
						        id="articles_vataccountfdvxp"
						        track-by="accountingaccount_id"
						        label="accountingaccount_label"
						        :placeholder="labelTitleCompteComptable"
						        :deselectLabel="deselectLabel"
						        :selectedLabel="selectedLabel"
						        :selectLabel="enter_to_select"
						        :options="tab_accounting_account_vat"
						        :searchable="true"
						        :show-labels="false"
						        :allow-empty="false"
						        :class="{ 'is-invalid': errors && errors.indexOf('Erreur vat compte comptable fdvxp') > -1 }"
						    >
						        <template slot="singleLabel" slot-scope="{ option }">{{ option.accountingaccount_label }}</template>
						        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						    </e-select>
						</div>
					</div>

					<div class="col-6">
						<div class="form-group">
						    <label for="articles_accountingaccountfdvex" class="col-form-label">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
						    <e-select
						        v-model="articles_accountingaccountfdvex"
						        id="articles_accountingaccountfdvex"
						        track-by="accountingaccount_id"
						        label="accountingaccount_label"
						        :placeholder="labelTitleCompteComptable"
						        :deselectLabel="deselectLabel"
						        :selectedLabel="selectedLabel"
						        :selectLabel="enter_to_select"
						        :options="tab_accounting_account"
						        :searchable="true"
						        :show-labels="false"
						        :allow-empty="false"
						        :class="{ 'is-invalid': errors && errors.indexOf('Erreur compte comptable fdvex') > -1 }"
						    >
						        <template slot="singleLabel" slot-scope="{ option }">{{ option.accountingaccount_label }}</template>
						        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						    </e-select>
						</div>
					</div>
					
					
					<div class="col-6">
						<div class="form-group">
						    <label for="articles_vataccountfdvex" class="col-form-label">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
						    <e-select
						        v-model="articles_vataccountfdvex"
						        id="articles_vataccountfdvex"
						        track-by="accountingaccount_id"
						        label="accountingaccount_label"
						        :placeholder="labelTitleCompteComptable"
						        :deselectLabel="deselectLabel"
						        :selectedLabel="selectedLabel"
						        :selectLabel="enter_to_select"
						        :options="tab_accounting_account_vat"
						        :searchable="true"
						        :show-labels="false"
						        :allow-empty="false"
						        :class="{ 'is-invalid': errors && errors.indexOf('Erreur vat compte comptable fdvex') > -1 }"
						    >
						        <template slot="singleLabel" slot-scope="{ option }">{{ option.accountingaccount_label }}</template>
						        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						    </e-select>
						</div>
					</div>
					
				</div>
			</template>
			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="article-tarif" class="col-form-label">{{ $t("compta.form.tarif_ht") }} *</label>
						<input type="text" id="article_tarif" class="form-control" v-model="article_tarif" :class="{ 'is-invalid': errors && errors.indexOf('article_tarif') > -1 }">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="vat_text" class="col-form-label">{{ $t("compta.form.dont_tva") }} *</label>
						<input type="text" id="vat_text" class="form-control" v-model="vat_text" :class="{ 'is-invalid': errors && errors.indexOf('vat_text') > -1 }">
					</div>
				</div>
			</div>


			<div class="row">
				<div class="col">
					<div class="form-group">
						<label for="commentaire" class="col-form-label">{{ $t("compta.form.commentaire") }}</label>
						<textarea id="commentaire" rows="5" v-model="commentaire" class="form-control"></textarea>
					</div>
				</div>
			</div>

			
			<div class="text-center mt-5" v-if="has_submit == false">
				<button @click="next(false)" class="btn btn-primary rounded-pill">
					<span>{{ $t('global.enregistrer_terminer') }} <font-awesome-icon :icon="['far', 'arrow-right']" class="ml-1"/></span>
				</button>
				<b-button @click="next(true)" class="btn btn-primary rounded-pill ml-1">
					{{ $t('tiers.ajouter_autre_article') }} <font-awesome-icon :icon="['fal', 'plus-circle']" class="ml-1"/> 
				</b-button>
			</div>

		</template>
		<template v-else>
			
			<div class="text-center mt-5" >
				<button @click="ok()" class="btn btn-primary rounded-pill">
					<span>{{ $t('mouvement.passer_ajout') }} <font-awesome-icon :icon="['far', 'arrow-right']" class="ml-1"/></span>
				</button>
			</div>
		</template>
	</div>
</template>


<script type="text/javascript">
	import Common from '@/assets/js/common'
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import Tiers from "@/mixins/Tiers.js"
	import Article from "@/mixins/Article.js"
	import Horse from "@/mixins/Horse.js"

	export default {
		name: "InvoiceSupplierHorse",
		mixins: [Invoice, Shutter, Tiers, Article, Horse],
		props: {
			tiers_id: Number,
			invoice: Object,
			increment: Number
		},
		data () {
			return {
				association: "cheval",
				working: false,
				has_submit: false,
				horses: [],
				tiers: [],
				horse: null,
				tier: null,
				commentaire: null,
				vat_text: null,
				articles: [],
				article: {},
				article_name: '',
				articles_code: '',
				article_tarif: '',
				article_fact:'',
				isLoadingEntity: false,
				entity: [],
				entity_model: null,
				type_of_invoice: null,
				accounting_account: null,
				articles_accountingaccountfdvex: null,
				articles_accountingaccountfdvxp: null,
				articles_vataccount: null,
				articles_vataccountfdvex: null,
				articles_vataccountfdvxp: null,
				articles_articlestype: null,
				tab_accounting_account: [],
				tab_accounting_account_vat: [],
				articles_type: [],
				toggle_form: false,
				articles_vat: {},
				articles_vats: [],
				labelTitleHorses: this.getTrad("tiers.search_horse"),
				labelTitleTiers: this.getTrad("tiers.search_tiers"),
				labelTitleArticles: "Rechercher un article",
				labelTitleVat: this.getTrad("tiers.choix_tva"),
				labelTitleCompteComptable: this.getTrad("accounting_account.rechercher"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				selectedLabel: this.getTrad("global.selected_label"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				labelTitleArticlesType: this.getTrad("compta.search_articles_type"),
				local_shutters: [],
				errors: [],
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			async preinit_component() {
				this.working = true
				this.isLoadingEntity = true

				this.type_of_invoice = (this.invoice.invoice.is_debours == true) ? "debours" : "avf"

				if(this.type_of_invoice == "debours")
				{
					let horses = await this.loadHorse(this.tiers_id)
					for (let i = 0; i < horses.length; i++) {
						this.horses.push({
							id: horses[i].horse.horse_id,
							name: horses[i].horse.horse_nom
						})
					}
				}
				else
				{
					let horses = await this.getHorses('light')
					for (let i = 0; i < horses.length; i++) {
						this.horses.push({
							id: horses[i].horse_id,
							name: horses[i].horse_nom
						})
					}
				}
				
				this.loadTiersEntity()
				.then(res => {
					if(this.type_of_invoice == "avf")
					{
						res = res.filter(tier => tier.tiers_id == this.tiers_id)
						this.entity_model = res[0]
					}
					this.entity = res
				})
				.finally(() => {
					this.isLoadingEntity = false
				})
				
				this.loadTiers()
				.then(res => {
					this.tiers = res
				})
				.finally(() => {
					this.isLoadingTiers = false
				})

				let articles_vats = await this.getVat()
				for (let k = 0; k < articles_vats.length; k++) {
					if(articles_vats[k].vat_value == "0.00")
					{
						this.articles_vats.push({
							id: articles_vats[k].vat_id,
							label: articles_vats[k].vat_label,
							value: articles_vats[k].vat_value,
						})

						this.articles_vat = {
							id: articles_vats[k].vat_id,
							label: articles_vats[k].vat_label,
							value: articles_vats[k].vat_value,
						}
					}
				}

				let articles_type = await this.getArticlesType()
				for (let i = 0; i < articles_type.length; i++) {
                        this.articles_type.push({
                            articlestype_id: articles_type[i].articlestype_id,
                            articlestype_label: this.getTrad(articles_type[i].articlestype_label),
                            articlestype_code: articles_type[i].articlestype_code,
                        })
                    }

				if(this.invoice.invoice_details)
				{
					this.entity_model = this.invoice.invoice_details.author
					this.association = 'cheval'

					if(this.invoice.invoice_details.invoicesuppliersdetails_horse)
					{
						this.horse = {
							id: this.invoice.invoice_details.horse.horse_id,
							name: this.invoice.invoice_details.horse.horse_nom,
						}
					}
					
					this.tier = this.invoice.invoice_details.tier
					this.article = this.invoice.invoice_details.article
					this.article_tarif = this.invoice.invoice_details.invoicesuppliersdetails_ht_format
					this.vat_text = this.invoice.invoice_details.invoicesuppliersdetails_vat_label
					this.commentaire = this.invoice.invoice_details.invoicesuppliersdetails_description
				}

				this.working = false
			},

			getNewShutter(){
				return {
					name: 'invoice-suppliers-horse-' + this.nbShutters,
					title: this.$t('tiers.associer_cheval') + "(" + (this.nbShutters + 1) + ")",
					cmpPath: 'components/ShutterScreens/Tier/InvoiceSupplierHorse',
					props: {
						tiers_id: this.tiers_id,
						invoice: {invoice: this.invoice.invoice},
						increment: this.nbShutters
					},
					onOk: this.ok,
					onCancel: this.cancelAdd
				}
			},

			async reloadArticle(tiers_id) {

				this.articles = []
				this.tab_accounting_account = []

				let elements_articles = await this.getArticlesDeboursOrAvf(this.invoice.invoice.invoice_id, tiers_id)

				this.tab_accounting_account = elements_articles.accounting_accounts
				this.tab_accounting_account_vat = elements_articles.accounting_accounts_vat

				this.articles = elements_articles.articles
			},

			toggle_create_article(bool){				
				this.toggle_form = bool
			},

			
			async next(continue_step) {
				let articles_id = null
				let articles_label = null
				let articles_ht = null
				let articles_ttc = null
				let articles_vat = null
				let vat_text = null

				this.errors = []

				// let articles_id 
				if(this.toggle_form == true)
				{
					if(Object.keys(this.articles_vat).length == 0)
					{
						this.errors.push("articles_vat")
					}

					if(this.article_name == "")
					{
						this.errors.push("article_name")
					}

					if(this.articles_code == "")
					{
						this.errors.push("articles_code")
					}

					if(this.article_tarif == "")
					{
						this.errors.push("article_tarif")
					}
					
					if(this.errors.length > 0)
					{
						return false
					}

					const tva = parseFloat(this.articles_vat.value)
					const ht = parseFloat(this.article_tarif.replace(",", ".").replace(" ", ""))
					let ttc = (this.articles_vat.value * ht) + ht

					//Je créer un article, puis je l'ajoute dans invoice details
					let articles = await this.addArticles(
						this.accounting_account.accountingaccount_id,
						this.articles_vat.id,
						this.article_name,
						this.articles_code,
						ht,
						ttc,
						this.articles_articlestype.articlestype_id,
						this.articles_accountingaccountfdvex.accountingaccount_id,
						this.articles_accountingaccountfdvxp.accountingaccount_id,
						this.articles_vataccount.accountingaccount_id,
						this.articles_vataccountfdvex.accountingaccount_id,
						this.articles_vataccountfdvxp.accountingaccount_id
					)
					articles_id = articles.retour.articles_id
					articles_label = articles.retour.articles_label
					articles_ht = articles.retour.articles_ht
					articles_ttc = articles.retour.articles_ttc
					articles_vat = articles.retour.articles_vat
				}
				else
				{
					if(Object.keys(this.article).length == 0)
					{
						this.errors.push("article_name_exist")
					}

					if(this.errors.length > 0)
					{
						return false
					}

					articles_id = this.article.articles_id
					articles_label = this.article.articles_label
					articles_ht = this.article_tarif
					articles_ttc = null
					articles_vat = this.article.articles_vat
				}

				if(this.vat_text == "" || this.vat_text == null || isNaN(this.vat_text * 1))
				{
					this.errors.push("vat_text")
					return false
				}


				let horse_id = null
				if(this.horse && this.horse.id && this.association == 'cheval') {
					horse_id = this.horse.id
				}

				let tiers_id = this.tiers_id
				if(this.tier && this.association != 'cheval')
				{
					tiers_id = this.tier.tiers_id
				}

				let paramsToSend = {};
				paramsToSend['details'] = []

				paramsToSend.details.push({
					author_id: this.entity_model.tiers_id,
					tiers_id: tiers_id,
					label: articles_label,
					qte: 1,
					htunit: articles_ht.toString().toFloat(),
					vat: articles_vat,
					horse_id: horse_id,
					articles_id: articles_id,
					description: '',
					invoicesuppliers_id: this.invoice.invoice.invoice_id,
					is_debours: (this.type_of_invoice == 'debours') ? 1 : 0,
					is_avf: (this.type_of_invoice == 'avf') ? 1 : 0,
					vat_text: this.vat_text.toString().toFloat(),
					commentaire: this.commentaire,
					id: (this.invoice.invoice_details && this.invoice.invoice_details.invoicesuppliersdetails_id) ? this.invoice.invoice_details.invoicesuppliersdetails_id : null,
				})

				let result = null 
				result = await this.createInvoiceSuppliersDetails(paramsToSend)
				if(result == null)
				{
					this.failureToast()
				}
				else
				{
					this.successToast()
					this.has_submit = true

					if(continue_step)
					{
						const new_shutter = this.getNewShutter()
						this.local_shutters.push(new_shutter)
						this.shutterPanel().open(new_shutter)
					}
					else
					{
						this.ok()
					}
				}
			},
			correctCode()
			{
				if(this.articles_code == "")
				{
					this.articles_code = this.accentsTidy(this.article_name).replace(/ /g, "_")
				}
			}
		},
		computed: {
			nbShutters(){
				return this.increment + 1 
			}
		},
		watch: {
			entity_model(val) {
				if(val != undefined && val != null)
				{
					this.reloadArticle(val.tiers_id)
				}
			},
			article(val) {

				if(this.invoice.invoice_details && this.invoice.invoice_details.invoicesuppliersdetails_ht_format && val.articles_id == this.invoice.invoice_details.article.articles_id)
				{
					this.article_tarif = this.invoice.invoice_details.invoicesuppliersdetails_ht_format
				}
				else
				{
					this.article_tarif = val.articles_ht
				}
			},
			'articles_accountingaccount'(val){
			    if(this.articles_accountingaccountfdvxp == null || Object.keys(this.articles_accountingaccountfdvxp).length == 0)
			    {
			        this.articles_accountingaccountfdvxp = val
			    }

			    if(this.articles_accountingaccountfdvex == null || Object.keys(this.articles_accountingaccountfdvex).length == 0)
			    {
			        this.articles_accountingaccountfdvex = val
			    }
			},
			'articles_vataccount'(val){
			    if(this.articles_vataccountfdvxp == null || Object.keys(this.articles_vataccountfdvxp).length == 0)
			    {
			        this.articles_vataccountfdvxp = val
			    }

			    if(this.articles_vataccountfdvex == null || Object.keys(this.articles_vataccountfdvex).length == 0)
			    {
			        this.articles_vataccountfdvex = val
			    }
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>